<template>

	<div>

		<!-- Add Or Edit Siderbar Modal -->
		<contracting-companies-list-add-and-edit
            :is-sidebar-active.sync="isSidebarActive"
            :data="contractingCompanyData"
            :mode="mode"
            @refresh-data="refetchData"
        />
		
		<!-- Table Container Card -->
		<b-card
			no-body
			class="mb-0">

			<div class="m-2">

				<!-- Table Top -->
				<b-row>

					<!-- Per Page -->
					<b-col
						cols="12"
						md="4"
						class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
						<label>Mostrar</label>
						<v-select
							v-model="perPage"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="perPageOptions"
							:clearable="false"
							class="per-page-selector d-inline-block mx-50"
						/>
						<label>registros</label>
					</b-col>
													   
					<!-- Search -->
					<b-col
						cols="12"
						md="6"
						class="ml-auto">
						<div class="d-flex">
							<b-form-input
								v-model="searchQuery"
								class="d-inline-block mr-1"
								placeholder="Buscar empresa contratista ..."
								trim
							/>
							<b-button
                                variant="primary"
                                class="ml-auto"
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                @click="showSidebar('add')"
                            >
								<span class="text-nowrap">Agregar Empresa Contratista</span>
							</b-button>
						</div>
					</b-col>

				</b-row>

			</div>

			<b-table
				ref="refContractingCompanyListTable"
				class="position-relative table-contracting-companies"
				:style="totalContractingCompanies === 1 ? 'height: 200px' : 'height: inherit'"
				:items="fetchContractingCompanies"
				responsive
				:fields="tableColumns"
				primary-key="id2"
				:sort-by.sync="sortBy"
				show-empty
				empty-text="No se encontraron registros coincidentes"
				:sort-desc.sync="isSortDirDesc"
			>

				<template #table-busy>
                    <div class="text-center text-primary my-2">
                        <b-spinner class="align-middle mb-1"></b-spinner>
                        <div><b>CARGANDO</b></div>
                    </div>
                </template>

				<template #head(contractors_count)="data">
					<span v-html="data.label"></span>
				</template>

				<template #head(students_count)="data">
					<span v-html="data.label"></span>
				</template>

				<template #cell(name)="data">
					<span class="d-block text-nowrap">						
						{{ data.item.name }}
					</span>
				</template>

				<template #cell(ruc)="data">
					<span class="d-block text-nowrap">						
						{{ data.item.ruc }}
					</span>
				</template>

				<template #cell(supervisory_companies)="data">
					<div v-html="getSupervisoryCompanies(data.item.supervisory_companies)"></div>
				</template>

				<template #cell(createdAt)="data">
					<span class="d-block text-nowrap">						
						{{ moment(data.item.createdAt).format('DD/MM/YYYY HH:mm:ss') }}
					</span>
				</template>
				
				<template #cell(actions)="data">
					<b-dropdown
						variant="link"
						no-caret
						:right="$store.state.appConfig.isRTL">

						<template #button-content>
							<feather-icon
								icon="MoreVerticalIcon"
								size="16"
								class="align-middle text-body"
							/>
						</template>

						<b-dropdown-item @click="showSidebar('update', data.item)">
							<feather-icon icon="EditIcon" />
							<span class="align-middle ml-50">Editar</span>
						</b-dropdown-item>

						<b-dropdown-item @click="deleteContractingCompany(data.item.id)">
							<feather-icon icon="TrashIcon" />
							<span class="align-middle ml-50">Eliminar</span>
						</b-dropdown-item>
					</b-dropdown>
				</template>

			</b-table>

			<div class="mx-2 mb-2">
				<b-row>

					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start">
						<span class="text-muted">Mostrando {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }} registros</span>
					</b-col>

					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end">

						<b-pagination
							v-model="currentPage"
							:total-rows="totalContractingCompanies"
							:per-page="perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item">

							<template #prev-text>
								<feather-icon
									icon="ChevronLeftIcon"
									size="18"
								/>
							</template>
							<template #next-text>
								<feather-icon
									icon="ChevronRightIcon"
									size="18"
								/>
							</template>
						</b-pagination>

					</b-col>

				</b-row>
			</div>
		</b-card>
		
	</div>

</template>

<script>

	import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
	import ContractingCompaniesListAddAndEdit from './ContractingCompaniesListAddAndEdit';
	import contractingCompanyStoreModule from '../contractingCompanyStoreModule';
	import useContractingCompaniesList from './useContractingCompaniesList';
	import { useToast } from 'vue-toastification/composition';
	import { ref, onUnmounted } from '@vue/composition-api';
	import VueSweetalert2 from 'vue-sweetalert2';
	import Ripple from 'vue-ripple-directive';
	import vSelect from 'vue-select';
	import store from '@/store';
	import moment from 'moment';
	import Vue from 'vue';    
	import 'animate.css';
	
	Vue.use(VueSweetalert2);

	export default {
		components: {
			ContractingCompaniesListAddAndEdit,
			vSelect
		},
		directives: {
            Ripple
        },
		data() {
            return {
                moment
            }
        },
		setup() {

			// USE TOAST
			const toast = useToast();

			// REFS
			const isSidebarActive = ref(false);
			const contractingCompanyData = ref({});
			const mode = ref('add');

			const CONTRACTING_COMPANY_APP_STORE_MODULE_NAME = 'app-contracting-company';

			// REGISTER MODULE
			if (!store.hasModule(CONTRACTING_COMPANY_APP_STORE_MODULE_NAME)) store.registerModule(CONTRACTING_COMPANY_APP_STORE_MODULE_NAME, contractingCompanyStoreModule);

			// UNREGISTER ON LEAVE
			onUnmounted(() => {
				if (store.hasModule(CONTRACTING_COMPANY_APP_STORE_MODULE_NAME)) store.unregisterModule(CONTRACTING_COMPANY_APP_STORE_MODULE_NAME);
			});

			const getSupervisoryCompanies = (data) => (data.length ? data.map(item => item.name).join('</br>') : '-');

			const showSidebar = (action, data = null) => {
                mode.value = action;

				if (action === 'add') {

					contractingCompanyData.value = {
						id: null,
						name: null,
						ruc: null,
						supervisory_companies: []
					};

				} else {

					const supervisory_companies = data.supervisory_companies.map(item => ({ value: item.id, label: `${item.name} (${item.ruc})` }));

					contractingCompanyData.value = {
						id: data.id,
						name: data.name,
						ruc: data.ruc,
						supervisory_companies
					};

				}

                isSidebarActive.value = true
            };
		
			const deleteContractingCompany = (id) => {
		
				Vue.swal({
					title: '¿Está seguro(a) de eliminar a la empresa contratista?',
					text: '¡Si no lo está, puede cancelar la acción!',
					icon: 'warning',
					showCancelButton: true,
					confirmButtonText: 'Sí, eliminar!',
					cancelButtonText: 'Cancelar',
					customClass: {
						confirmButton: 'btn btn-primary',
						cancelButton: 'btn btn-outline-danger ml-1'
					},
					showClass: {
						popup: 'animate__animated animate__tada'
					},
					buttonsStyling: false
				}).then(result => {

					if (result.value) 
					{
						store.dispatch('app-contracting-company/deleteContractingCompany', { id })
							.then( response => {

								refetchData();

								toast({
									component: ToastificationContent,
									props: {
										title: response.data.message,
										icon: 'CheckIcon',
										variant: 'success'
									}
								});

							})
							.catch( () => {

								toast({
									component: ToastificationContent,
									props: {
										title: 'Error al eliminar a la empresa contratista',
										icon: 'AlertTriangleIcon',
										variant: 'danger'
									}
								});

							});
					}

				});

			};
			
			const {
				fetchContractingCompanies,
				tableColumns,
				perPage,
				currentPage,
				totalContractingCompanies,
				dataMeta,
				perPageOptions,
				searchQuery,
				sortBy,
				isSortDirDesc,
				refContractingCompanyListTable,
				refetchData
			} = useContractingCompaniesList();
			
			return {
				fetchContractingCompanies,
				tableColumns,
				perPage,
				currentPage,
				totalContractingCompanies,
				dataMeta,
				perPageOptions,
				searchQuery,
				sortBy,
				isSortDirDesc,
				refContractingCompanyListTable,
				refetchData,

				// REFS
				isSidebarActive,
				contractingCompanyData,
				mode,
				
				// ACTIONS
				getSupervisoryCompanies,
				showSidebar,
				deleteContractingCompany
			}
		}
	}

</script>

<style lang="scss" scoped>

	.media {
        align-items: center;
    }

	.per-page-selector {
		width: 90px;
	}    

</style>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-select.scss';
	@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>