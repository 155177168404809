import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';

// Notification
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { useToast } from 'vue-toastification/composition';

export default function useContractingCompaniesList() {

    // Use toast
    const toast = useToast();

    const refContractingCompanyListTable = ref(null);

    const styleColumns = {
        thClass: 'text-center',
        tdClass: 'text-center'
    }

    // Table Handlers
    let tableColumns = [
        { key: 'name', sortable: true, label: 'EMPRESA' },
        { key: 'RUC', sortable: true, label: 'RUC' },
        { key: 'supervisory_companies', sortable: true, label: 'SUBSIDIARIA(S)' },
        { key: 'contractors_count', sortable: true, label: 'CANTIDAD <br> CONTRATISTAS', ...styleColumns },
        { key: 'students_count', sortable: true, label: 'CANTIDAD <br> USUARIOS', ...styleColumns },
        { key: 'createdAt', sortable: true, label: 'FECHA REGISTRO', ...styleColumns },
        { key: 'actions', sortable: false, label: 'OPCIONES', ...styleColumns }
    ];

    const perPage = ref(10);
    const totalContractingCompanies = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref('');
    const sortBy = ref('createdAt');
    const isSortDirDesc = ref(true);

    const dataMeta = computed(() => {
        const localItemsCount = refContractingCompanyListTable.value ? refContractingCompanyListTable.value.localItems.length : 0;
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalContractingCompanies.value
        }
    });

    const refetchData = () => {
        refContractingCompanyListTable.value.refresh();
    };

    watch([currentPage, perPage, searchQuery], () => {
        refetchData();
    });

    const fetchContractingCompanies = (ctx, callback) => {
        store
            .dispatch('app-contracting-company/fetchContractingCompanies', {
                q: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value
            })
            .then(response => {
                const { contractingCompanies, total } = response.data;
                callback(contractingCompanies);
                totalContractingCompanies.value = total;
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error al obtener la lista de empresas contratistas',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger'
                    }
                });
            })
    };

    return {
        fetchContractingCompanies,
        tableColumns,
        perPage,
        currentPage,
        totalContractingCompanies,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refContractingCompanyListTable,

        refetchData
    }
}
