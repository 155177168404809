import axios from '@axios';

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchContractingCompanies(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/contracting-companies', { params: queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        addContractingCompany(ctx, { payload }) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/contracting-companies/create', payload)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        updateContractingCompany(ctx, { id, payload }) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/contracting-companies/${id}/update`, payload)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        deleteContractingCompany(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/contracting-companies/${id}/delete`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        }
    }
}